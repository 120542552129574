<template>
    <section v-if="initiationId">
        <div id="reopen-job-vacancy-v2" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="closeModal"/>
                <div
                    class="uk-modal-header uk-flex-inline uk-width-1-1"
                    style="padding: 16px 20px !important;"
                >
                    <h2 class="uk-modal-title header-font uk-margin-remove">Reopen this Vacancy</h2>
                </div>
                <section class="uk-modal-body space-y-7.5 uk-display-block" style="padding: 20px; color: black;">
                    <div class="uk-width-1-1 uk-display-block">
                        <label for="due_date" class="font-semibold">
                            Due Date <span class="font-bold text-red">*</span>
                        </label>
                        <div class="uk-width-1-1" style="margin-top: 8px;">
                            <input v-model="form.isCustomDate" :value="false" class="uk-radio" type="radio" id="custom_date_false">
                            <label for="custom_date_false" class="uk-margin-small-left uk-margin-small-right">
                                Default (3 days ahead from now: {{ defaultDueDate }})
                            </label>
                        </div>
                        <div class="uk-width-1-1 items-center" style="margin-top: 12px;">
                            <input v-model="form.isCustomDate" :value="true" class="uk-radio uk-margin-remove" type="radio" id="custom_date_true">
                            <label for="custom_date_true" class="uk-margin-small-left uk-margin-small-right">Custom</label>

                            <div class="uk-form-controls">
                                <datetime
                                    v-model="form.dueDate"
                                    id="due_date"
                                    placeholder="dd/mm/yyyy"
                                    input-class="uk-input"
                                    type="date"
                                    :disabled="!form.isCustomDate"
                                    :min-datetime="minimumDueDate"
                                    format="dd MMM yyyy"
                                    :value-zone="this.timezone"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="uk-width-1-1 uk-margin-top">
                        <label for="start_working" class="font-semibold">
                            Start Work <span class="font-bold text-red">*</span>
                        </label>

                        <div class="uk-form-controls" style="margin-top: 8px; width: fit-content;">
                            <datetime
                                v-model="form.startWork"
                                id="start_working"
                                placeholder="dd/mm/yyyy"
                                input-class="uk-input"
                                type="date"
                                :min-datetime="form.dueDate || minimumDueDate"
                                format="dd MMM yyyy"
                                :value-zone="this.timezone"
                            />
                        </div>
                    </div>

                    <div class="uk-width-1-1">
                        <label>
                            <input v-model="form.isEditJob" class="uk-checkbox" type="checkbox">
                            {{" "}} Also update the job vacancy informations?
                        </label>
                    </div>
                </section>
                <div class="uk-modal-footer justify-between">
                    <button
                        class="uk-button"
                        style="border: 1px solid #EF5350; color: #EF5350; background: #FFF;"
                        @click="closeModal"
                    >
                        Cancel
                    </button>

                    <button
                        class="uk-button text-white"
                        :class="[(submitLoading || !form.startWork) && 'btn-disabled' || 'uk-button-primary']"
                        @click="proceedReopen"
                    >
                        <template v-if="submitLoading">
                            <span uk-spinner="ratio: 0.5"></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </template>
                        <template v-else-if="!submitLoading">
                            Proceed
                        </template>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import {Datetime} from 'vue-datetime';
import {mapActions} from 'vuex';

import {notificationDanger} from '@/utils/notification';
import formatter from '@/utils/formatter';

export default {
    name: "ModalReopenJobVacancyV2",
    components: {
        Datetime,
    },
    props: {
        initiationId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            submitLoading: false,
            form: {
                isCustomDate: false,
                dueDate: null,
                startWork: null,
                isEditJob: false,
            },
            timezone: null,
        };
    },
    computed: {
        minimumDueDate() {
            const now = Date.now();
            return new Date(now + 3 * 24 * 60 * 60 * 1000).toISOString();
        },
        defaultDueDate() {
            return formatter.dateCustomFormat({ date: this.minimumDueDate, locale: 'en' });
        },
        defaultDueISO() {
            const date = new Date(this.minimumDueDate);
            date.setHours(0, 0, 0, 0);
            date.setMinutes(0 - date.getTimezoneOffset());

            return date.toISOString().replace('Z', this.timezoneOffset);
        },
        timezoneOffset() {
            return formatter.dateTimezoneOffset(new Date());
        },
    },
    mounted() {
        this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    beforeDestroy() {
        this.resetForm();
    },
    methods: {
        ...mapActions({
            updateStatus: 'initiation/updateStatusV4'
        }),
        async proceedReopen() {
            try {
                this.submitLoading = true;
                const { isEditJob, startWork, isCustomDate, dueDate } = this.form;

                const params = {
                    timeout: 750,
                    next_id: this.initiationId,
                    modal_name: 'reopen_confirm',
                    start_work: startWork,
                    due_date: (isCustomDate && dueDate || this.defaultDueISO),
                    is_edit: isEditJob,
                };

                if (isCustomDate && !dueDate) {
                    throw new Error('Please fill the due data');
                } else if (!startWork) {
                    throw new Error('Please fill the start work');
                }

                if (isEditJob) {
                    params.modal_name = 'form_job';
                }

                setTimeout(() => {
                    this.$emit("success", params);
                    this.resetForm();
                }, 250);

            } catch (e) {
                notificationDanger(e);
            } finally {
                setTimeout(() => {
                    this.submitLoading = false;
                    window.UIkit.modal("#reopen-job-vacancy-v2").$destroy(true);
                }, 750);
            }
        },
        closeModal(timeout) {
            const params = [];
            if (timeout > 0) params.push(timeout);

            this.resetForm();
            window.UIkit.modal("#reopen-job-vacancy-v2").$destroy(true);
            this.$emit("cancel", params);
        },
        resetForm() {
            this.form = {
                isCustomDate: false,
                dueDate: null,
                startWork: null,
                isEditJob: false,
            };
        },
    },
};
</script>
<style scoped>
.btn-disabled {
    background-color: #979797;
    pointer-events: none;
}

.text-white {
    color: #fff !important;
}

.text-red {
    color: #EF5350 !important;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.items-center {
    display: flex;
    align-items: center;
}

.justify-between {
    display: flex;
    justify-content: space-between;
}

.width-fit {
    width: fit-content;
}

.header-font {
    font-size: 24px;
    line-height: 1.5;
    margin: 0 16px;
    letter-spacing: -0.2px;
}

input[type="radio"]#custom_date_true {
    padding-top: 4px !important;
}

section.uk-modal-body > * + * {
    margin-top: 1.875rem !important;
}
</style>
