import { render, staticRenderFns } from "./Reopen.vue?vue&type=template&id=718019ca&scoped=true"
import script from "./Reopen.vue?vue&type=script&lang=js"
export * from "./Reopen.vue?vue&type=script&lang=js"
import style0 from "./Reopen.vue?vue&type=style&index=0&id=718019ca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718019ca",
  null
  
)

export default component.exports